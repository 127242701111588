import React from 'react';
import { useRouter } from 'next/router';
import { useUser } from '../hooks';

function Home() {
  const router = useRouter();
  const [user] = useUser();
  React.useEffect(() => {
    if (user?._id) {
      router?.push('/app');
    } else if (!router?.asPath?.includes('reset-password')) {
      router?.push('/sign-in');
    }
  });
  return <div />;
}

export default Home;
